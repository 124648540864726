
import { Vue, Options } from 'vue-class-component';
import {
  CopdRelationship,
  CopdProgramResponseData,
  CopdProgramSettingResource,
  Organisation,
  Patient,
  CopdProgramMeasurement,
  CopdProgramReview,
  CopdDischarge,
  CopdProgramIncludedReviews,
  CopdProgramIncludedConsults,
  CopdProgramConsult,
  Tag,
  TagGroupName,
  TagGroup,
  CopdAllProgramItem
} from '@/models';
import {
  CopdProgramService,
  CopdPatientService,
  CopdProgramMeasurementService
} from '@/services/api';
import {
  BaseIcon,
  BasePagination,
  BaseButton,
  BaseCard,
  BasePopover,
  CopdMeasurement,
  PopoverButton,
  PatientHomeMonitoringActionModal,
  BaseTooltip
} from '@/lib/components';
import axios, { CancelTokenSource } from 'axios';
import { IValidationError } from '@/lib';
import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import kebabCase from 'lodash-es/kebabCase';
import { recordUserEvent, Pathways } from '@/helpers/aws.helper';
import ButtonLink from '@/lib/components/Button/ButtonLink.vue';
import PopoverLink from '@/lib/components/Popover/PopoverLink.vue';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { FEATURES } from '@/constants';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

dayjs.extend(utc);

@Options({
  props: {
    patient: {
      type: Object,
      required: true
    },
    programId: {
      type: String,
      required: true
    },
    organisationId: {
      type: String,
      required: true
    }
  },
  components: {
    PopoverLink,
    BaseIcon,
    BasePagination,
    BaseButton,
    BaseCard,
    ButtonLink,
    PatientHomeMonitoringActionModal,
    BasePopover,
    CopdMeasurement,
    PopoverButton,
    BaseTooltip
  }
})
export default class PatientHomeMonitoringPage extends Vue {
  patient!: Patient;
  organisationId!: string;
  programId!: string;
  request: CancelTokenSource | null = null;
  perPage = 0;
  total = 0;
  program: CopdProgramResponseData | null = null;
  tags: Array<Tag> = [];
  actionModalStatus = '';
  errors: IValidationError = {
    errors: {},
    message: ''
  };

  discharged: CopdDischarge = {
    created_at: '',
    created_by: '',
    reason: ''
  };

  assignedOrg: Organisation | null = null;
  assignedHospitalTeam: Tag | null = null;
  assignedCommunityTeam: Tag | null = null;
  onHomeMonitoring: Tag | null = null;
  isOnHomeMonitoring: boolean;
  copdProgramService = new CopdProgramService();
  copdProgramMeasurementService = new CopdProgramMeasurementService(
    this.programId
  );

  measurements: CopdProgramMeasurement[] = [];
  unreviewedMeasurements: CopdProgramMeasurement[] = [];
  measurementIncludes: (CopdProgramReview | CopdProgramSettingResource)[] = [];
  unreviewedMeasurementIncludes: (
    | CopdProgramReview
    | CopdProgramSettingResource
    )[] = [];

  measurementsLoaded = false;
  invitationSent: boolean = this.patient.copd_home_monitoring_invite_created_at
    ? this.patient.copd_home_monitoring_invite_created_at !== null
    : false;

  copdPatientService = new CopdPatientService();
  latestConsult: CopdProgramConsult | null = null;
  allPrograms: CopdAllProgramItem[] = [];
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();

  mounted() {
    this.fetchProgram();
    this.fetchMeasurements();
    this.fetchAllPrograms();

    const unWatchRoute = this.$watch('$route', async (to, from) => {
      if (from.path === to.path && from.query !== to.query) {
        await this.fetchMeasurements();
      } else {
        unWatchRoute();
      }
    });

    this.recordEvent('entered patient virtual ward page');
  }

  beforeUnmount() {
    this.recordEvent('exited patient virtual ward page');
  }

  get tagsFeatureEnabled(): boolean {
    return isFeatureFlagEnabled(FEATURES.TAGS);
  }

  get invitationAccepted(): boolean {
    return !!this.patient.copd_home_monitoring_invite_accepted_at;
  }

  get permittedToViewMeasurements(): boolean {
    return (
      this.sessionStore.permissions?.includes(
        'copd-home-monitoring:program-measurement:read'
      ) || false
    );
  }

  get toBeReviewedList(): string[] {
    return this.unreviewedMeasurements.map((measurement) => measurement.id);
  }

  get toBeReviewedCount(): string {
    return this.unreviewedMeasurements.length.toString();
  }

  get page(): number {
    return Number(this.$route.query.page) || 1;
  }

  get allMeasurements(): CopdProgramMeasurement[] {
    return [...this.unreviewedMeasurements, ...this.measurements];
  }

  get allMeasurementIncludes(): (
    | CopdProgramReview
    | CopdProgramSettingResource
    )[] {
    return [...this.unreviewedMeasurementIncludes, ...this.measurementIncludes];
  }

  get dischargeKey() {
    const reason = this.discharged ? this.discharged.reason : '';
    return reason ? kebabCase(reason) : null;
  }

  get isAllowedForCurrentOrganisation(): boolean {
    const programOwnerOrgId = this.allPrograms.find(
      (program) => program.id === this.programId)?.owner_organisational_unit_id;
    const programAssignedOrgId = this.assignedOrg?.id;

    return this.organisationId === programOwnerOrgId || this.organisationId === programAssignedOrgId;
  }

  updateAssignedHospitalTeam(tag: Tag): void {
    this.assignedHospitalTeam = tag;
  }

  updateAssignedCommunityTeam(tag: Tag): void {
    this.assignedCommunityTeam = tag;
  }

  updateIsOnHomeMonitoring(): void {
    this.isOnHomeMonitoring = this.onHomeMonitoring?.attributes?.name?.toLowerCase() === 'yes';
  }

  updateOnHomeMonitoring(tag: Tag): void {
    this.onHomeMonitoring = tag;
    this.updateIsOnHomeMonitoring();
  }

  toggleModal(refreshList = false) {
    if (this.actionModalStatus === 'invite') {
      this.toggleInviteModal();
    }
    if (this.actionModalStatus === 'assign') {
      this.toggleAssignToOrganisationModal();
    }
    if (this.actionModalStatus === 'review') {
      this.toggleReviewModal();
    }
    if (this.actionModalStatus === 'settings') {
      this.togglePatientSettingsModal();
    }
    if (this.actionModalStatus === 'discharge') {
      this.toggleDischargeModal();
    }
    if (this.actionModalStatus === 'consult') {
      this.toggleConsultModal();
    }

    // Re fetch the list if needed
    refreshList && this.fetchMeasurements() && this.fetchProgram();
  }

  toggleInviteModal() {
    this.actionModalStatus = this.actionModalStatus === '' ? 'invite' : '';
  }

  toggleAssignToOrganisationModal() {
    this.actionModalStatus = this.actionModalStatus === '' ? 'assign' : '';
  }

  toggleReviewModal() {
    this.actionModalStatus = this.actionModalStatus === '' ? 'review' : '';
  }

  togglePatientSettingsModal() {
    this.actionModalStatus = this.actionModalStatus === '' ? 'settings' : '';
  }

  toggleDischargeModal() {
    this.actionModalStatus = this.actionModalStatus === '' ? 'discharge' : '';
  }

  toggleConsultModal() {
    this.actionModalStatus = this.actionModalStatus === '' ? 'consult' : '';
  }

  settingsFor(measurement: CopdProgramMeasurement): CopdProgramSettingResource {
    const settings =
      (this.allMeasurementIncludes.find(
        (included) =>
          included.type === 'copdHomeMonitoringProgramSettings' &&
          included.id === measurement.relationships.settings?.id
      ) as CopdProgramSettingResource) || null;

    if (settings === null) {
      throw new Error(
        `Unable to find program settings for measurement: ${measurement.id}`
      );
    }

    return settings;
  }

  reviewFor(measurement: CopdProgramMeasurement): CopdProgramReview | null {
    return (
      (this.allMeasurementIncludes.find(
        (included) =>
          included.type === 'copdHomeMonitoringProgramReviews' &&
          included.id === measurement.relationships.review?.id
      ) as CopdProgramReview) || null
    );
  }

  updateAssignedOrg(assignedOrg: Organisation) {
    this.assignedOrg = assignedOrg;
  }

  recordEvent(event: string) {
    recordUserEvent(event, Pathways.COPD, this.patient.id);
  }

  updateInvitationBanner() {
    this.invitationSent = true;
    this.patient.copd_home_monitoring_invite_created_at = dayjs()
      .utc()
      .format();
    this.toggleInviteModal();
    this.notificationStore.snackbar = {
      label: this.$t('custom.uhb.patient.invite-sent-message')
    };
  }

  async fetchProgram() {
    const assignedOrgKey = 'assigned_organisational_unit';
    const dischargedKey = 'discharged_review';
    const latestConsultKey = 'latest_consult';
    const tagsKey = 'tags';
    const tagGroupsKey = 'tagGroups';

    try {
      this.request = axios.CancelToken.source();
      const response = await this.copdProgramService.getProgramWithIncludes(
        this.programId as string,
        {
          cancelToken: this.request.token,
          params: {
            include: `${assignedOrgKey},${dischargedKey},${latestConsultKey},${tagsKey},${tagGroupsKey}`
          }
        }
      );
      this.program = response.data;
      this.tags = response.included?.filter(
        (resource) => resource.type === 'tags'
      ) as unknown as Tag[];
      const programIncludes = response.included || [];
      this.errors = {
        errors: {},
        message: ''
      };
      if (
        assignedOrgKey in this.program.relationships &&
        this.program.relationships[assignedOrgKey] !== null
      ) {
        const relationship: CopdRelationship = this.program.relationships[
          assignedOrgKey
        ] as CopdRelationship;
        this.assignedOrg = programIncludes.find(
          (x: Organisation) => x.id === relationship.id
        ) as Organisation;
      }

      this.assignedHospitalTeam =
        this.tags?.find(
          (tag) =>
            (this.program?.relationships.tags as Array<CopdRelationship>)
              .map((t) => t.id)
              .includes(tag.id) &&
            programIncludes.find(
              (t: TagGroup) =>
                t.id === tag.relationships?.group?.id &&
                t.attributes.name === TagGroupName.HospitalTeam
            )
        ) || null;

      this.assignedCommunityTeam =
        this.tags?.find(
          (tag) =>
            (this.program?.relationships.tags as Array<CopdRelationship>)
              .map((t) => t.id)
              .includes(tag.id) &&
            programIncludes.find(
              (t: TagGroup) =>
                t.id === tag.relationships?.group?.id &&
                t.attributes.name === TagGroupName.CommunityTeam
            )
        ) || null;

      this.onHomeMonitoring =
        this.tags?.find(
          (tag) =>
            (this.program?.relationships.tags as Array<CopdRelationship>)
              .map((t) => t.id)
              .includes(tag.id) &&
            programIncludes.find(
              (t: TagGroup) =>
                t.id === tag.relationships?.group?.id &&
                t.attributes.name === TagGroupName.HomeMonitoring
            )
        ) || null;

      this.updateIsOnHomeMonitoring();

      if (dischargedKey in this.program.relationships) {
        const relationship: CopdRelationship = this.program.relationships
          .discharged_review as CopdRelationship;
        this.discharged = programIncludes.find(
          (x: CopdProgramIncludedReviews) =>
            relationship && x.id === relationship.id
        )?.attributes as CopdDischarge;
      }

      if (latestConsultKey in this.program.relationships) {
        const relationship: CopdRelationship = this.program.relationships
          .latest_consult as CopdRelationship;

        this.latestConsult = programIncludes.find(
          (x: CopdProgramIncludedConsults) =>
            relationship && x.id === relationship.id
        )?.attributes as CopdProgramConsult;
      }

      this.request = null;
    } catch (e) {
      if (!axios.isCancel(e)) {
        if (e.response) {
          this.errors = e.response;
        } else {
          throw e;
        }
      }
    }
  }

  async fetchMeasurements() {
    this.measurementsLoaded = false;

    const requests = [this.fetchReviewedMeasurements()];

    if (this.page === 1) {
      requests.push(this.fetchUnreviewedMeasurements());
    } else {
      this.unreviewedMeasurements = [];
    }

    await Promise.all(requests);

    this.measurementsLoaded = true;
  }

  async fetchReviewedMeasurements() {
    try {
      this.request = axios.CancelToken.source();
      const response =
        await this.copdProgramMeasurementService.indexWithIncludes({
          cancelToken: this.request.token,
          params: {
            include: 'review,settings',
            'filter[has_review]': true,
            page: this.page
          }
        });

      this.measurements = response.data;

      this.measurementIncludes = response.included || [];

      this.perPage = response?.meta?.per_page || 4;
      this.total = response?.meta?.total || 0;
    } catch (e) {
      this.errors = e.response;
    }
  }

  async fetchUnreviewedMeasurements() {
    try {
      this.request = axios.CancelToken.source();
      const response =
        await this.copdProgramMeasurementService.indexWithIncludes({
          cancelToken: this.request.token,
          params: {
            include: 'review,settings',
            'filter[has_review]': false
          }
        });

      this.unreviewedMeasurements = response.data;

      this.unreviewedMeasurementIncludes = response.included || [];
    } catch (e) {
      this.errors = e.response;
    }
  }

  wasCreatedOnTheSameDay(
    a: CopdProgramMeasurement,
    b: CopdProgramMeasurement
  ): boolean {
    return this.toDate(a.attributes.created_at).isSame(
      this.toDate(b.attributes.created_at),
      'day'
    );
  }

  wasNotCreatedOnTheSameDay(
    a: CopdProgramMeasurement,
    b: CopdProgramMeasurement
  ): boolean {
    return !this.wasCreatedOnTheSameDay(a, b);
  }

  toDate(value: string): Dayjs {
    return dayjs.utc(value).local();
  }

  navToRecordMeasurements(): void {
    this.$router.push({
      name: 'copd-new-measurement',
      params: {
        organisationId: this.organisationId,
        patientId: this.patient.id,
        programId: this.programId
      }
    });
  }

  async changePage(page: number) {
    await this.$router.replace({
      path: this.$route.path,
      query: {
        ...this.$route.query,
        page: page.toString()
      }
    });
  }

  formatDate(date: string) {
    return dayjs(date).format('D MMM YYYY');
  }

  async fetchAllPrograms() {
    try {
      const response = await this.copdProgramService.getAllPrograms(
        this.patient.id
      );
      this.allPrograms = response.data;
    } catch (e) {
      this.errors = e.response;
    }
  }
}
